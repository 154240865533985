<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Service Calls</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'serviceCallsForm', params: {id: 0} }"
                        >
                            New Service Call
                        </v-btn>
                    </template>
                    <span>New Service Call</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Customer Source</label>
                <v-combobox
                    v-model="filter.customerOriginSelected"
                    :items="listCustomerOrigin" 
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Customer</label>
                <v-combobox
                    v-model="filter.sellerSelected"
                    :items="listSellers" 
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Status</label>
                <v-combobox
                    v-model="filter.listStatusSelected"
                    :items="listStatus" 
                    item-text="statusDescription"
                    item-value="id"
                    multiple
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.redFlag="{ item }">
                        <v-icon v-if="item.redFlag == 1" color="var(--color__yellow)">mdi mdi-flag</v-icon>
                        <v-icon v-if="item.redFlag == 2" color="var(--color__red)">mdi mdi-flag</v-icon>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'serviceCallsForm'"
                            :showButtons="{
                                edit: item.status === 0 || item.status === 2,
                                cancel2: item.status === 0 || item.status === 2,
                                close: item.status === 0 || item.status === 2,
                                view: item.status === 1 || item.status === 3,
                                download: true
                            }"
                            @confirmCancel2="showNotes('[Cancel]', item.id)"
                            @confirmClose="showNotes(typeClose, item.id)"
                            @confirmView="showView(item)"
                            @confirmDownload="confirmDownload"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showDialogNotes"
            transition="dialog-top-transition"
            persistent
            width="900"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    <span v-if="checkView == false">
                        Service Call - {{ type }}
                    </span>
                    <span v-else>
                        History
                    </span>
                </v-card-title>

                <v-card-text 
                    v-if="checkView == false"
                    style="margin-top: 20px;"
                >

                    <v-row 
                        v-if="type == typeClose"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Service Type</label>
                            <v-combobox
                                v-model="serviceTypeSelected"
                                :items="listServiceType"
                                item-text="serviceTypeDescription"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>

                        <v-col
                            v-if="isBillable"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Type of Payment</label>
                            <v-combobox
                                v-model="typePaymentSelected"
                                :items="listTypePayments"
                                item-text="typePaymentsDescription"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row 
                        v-if="type == typeClose && showCheckOrWireNumber && isBillable"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>{{ textCheckOrWireTransfer }} </label>
                            <v-text-field
                                v-model="checkNumber" 
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="type == typeClose && isBillable"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuPaymentDate"
                                v-model="mnuPaymentDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Payment Date</label>
                                    <v-text-field
                                        v-model="paymentDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        :rules=[validations.required]
                                        @blur="paymentDate = parseDate(paymentDateFormatted)"
                                        readonly
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="paymentDate"
                                    no-title
                                    @input="mnuPaymentDate = false"
                                    :max="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="type == typeClose && isBillable"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Value</label>
                            <v-text-field
                                v-model="value" 
                                v-formatMoney="moneyFormat"
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                                :disabled="userLoggedGetters.profile.systemAdmin != 1"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            v-if="checkView == false"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="notes"
                                :rules=[validations.required]
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text 
                    v-else
                    style="margin-top: 20px;"
                >
                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-data-table
                                :headers="headersHistory"
                                :items="listHistory"
                                hide-default-footer
                                no-data-text="No history registered"
                            >                    
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.id" 
                                        :showButtons="{
                                            viewPaymentsDetails: item.serviceType != null && item.serviceType != undefined && item.serviceType == 1,
                                            viewPhotos: item.qtdPictures > 0,
                                        }"
                                        @viewPaymentsDetails="viewPaymentsDetails()"
                                        @viewPhotos="viewPhotos(item.id)"
                                    />
                                </template>                            
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            v-if="checkView == false"
                            class="mx-2 buttonCancelDialog"
                            style=""
                            outlined
                            @click="showDialogNotes = false;"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            v-if="checkView == false"
                            class="mx-2"
                            outlined
                            @click="confirm()"
                        >
                            Confirm
                        </v-btn>

						<v-btn
                            v-if="checkView == true"
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="showDialogNotes = false;"
						>
							Dismiss
						</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showDialogNotesPictures"
            transition="dialog-top-transition"
            persistent
            width="900"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Pictures
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col
                            v-for="(itemPhoto, index) in serviceCallsNotesPhotos.filter(pht => pht.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <v-card max-width="200">
                                <v-img
                                    :src="itemPhoto.src"
                                    :lazy-src="itemPhoto.src"
                                    aspect-ratio="1"
                                    max-height="200"
                                    max-width="200"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="showDialogNotesPictures = false;"
						>
							Dismiss
						</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showDialogPaymentsDetails"
            transition="dialog-top-transition"
            persistent
            width="700"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Payment Detail
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <label>Type</label>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <label>Number</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <label>Payment Date</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <label>Value</label>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="margin-bottom: 20px;"
                        >
                            <v-divider />
                        </v-col>
                    </v-row>

                    <!-- PAYMENT -->
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentDetails.paymentTypeDescription }} </span>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentDetails.paymentCheckNumber }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentDetails.paymentDateFormatted }} </span>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <span> {{ paymentDetails.paymentValueFormatted }} </span>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="showDialogPaymentsDetails = false"
						>
							Dismiss
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { required } from "@/utilities/Rules";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT 
    } from "@/utilities/Enums";
    import { formaterDecimalBRServer } from '@/utilities/Utils';
    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: vm => ({

            loading: false,

            filter: {
                fastSearch: '',
                customerOriginSelected: null,
                sellerSelected: null,
                listStatusSelected: [],
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Author", value: "userNameRegister", sortable: true },
                { text: "Customer Source", value: "customerOriginDescription", sortable: true },
                { text: "Customer", value: "customerName", sortable: true },
                { text: "Flag", value: "redFlag", sortable: true },
                { text: "Phone", value: "customerPhone", sortable: true },
                { text: "Email", value: "customerEmail", sortable: true },
                { text: "Scheduled On", value: "estimatedDateFormatted", sortable: true },
                { text: "Ended On", value: "closedCanceledDateFormatted", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            headersHistory: [
                { text: "Registered On", value: "dateRegisterFormatted", sortable: true },
                { text: "Registered By", value: "userName", sortable: true },
                { text: "Type", value: "serviceTypeDescription", sortable: true },
                { text: "Note", value: "description", sortable: true },
                { text: "Qtd Pic", value: "qtdPictures", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            listHistory: [],

            listServiceCalls: [],
            listServiceCallsFiltered: [],
            serviceCallsNotesPhotos: [],

            listCustomerOrigin: [],
            listSellers: [],
            listStatus: [],

            type: '',
            typeClose: '[Close]',
            statusId: 0,
            id: 0,
            showDialogNotes: false,
            showDialogNotesPictures: false,
            notes: '',
            checkView: false,
            listServiceType: [],
            listTypePayments: [],
            serviceTypeSelected: null,
            typePaymentSelected: null,
            checkNumber: '',
            value: "$ 0,00",

            today: (new Date(Date.now() - ( new Date() ).getTimezoneOffset() * 60000) ).toISOString().substr(0, 10),
            paymentDate: vm.today,
            paymentDateFormatted: vm.formatDate(vm.today),
            mnuPaymentDate: false,

            showDialogPaymentsDetails: false,

            itemServiceCall: null,
            paymentDetails: {
                paymentTypeDescription: null,
                paymentCheckNumber: null,
                paymentDateFormatted: null,
                paymentValueFormatted: null
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },
            
            validations: {
                required: required
            }
            
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listServiceCallsFiltered.filter((serviceFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var userNameRegister = serviceFilter.userNameRegister != null && serviceFilter.userNameRegister != undefined ? serviceFilter.userNameRegister.toLowerCase().match(filter) : ""
                    var dateRegisterFormatted = serviceFilter.dateRegisterFormatted != null && serviceFilter.dateRegisterFormatted != undefined ? serviceFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    var customerName = serviceFilter.customerName != null && serviceFilter.customerName != undefined ? serviceFilter.customerName.toLowerCase().match(filter): ""
                    var customerOriginDescription = serviceFilter.customerOriginDescription != null && serviceFilter.customerOriginDescription != undefined ? serviceFilter.customerOriginDescription.toLowerCase().match(filter): ""
                    var customerPhone = serviceFilter.customerPhone != null && serviceFilter.customerPhone != undefined ? serviceFilter.customerPhone.toLowerCase().match(filter) : ""
                    var userNameResponsible = serviceFilter.userNameResponsible != null && serviceFilter.userNameResponsible != undefined ? serviceFilter.userNameResponsible.toLowerCase().match(filter) : ""
                    var estimatedDateFormatted = serviceFilter.estimatedDateFormatted != null && serviceFilter.estimatedDateFormatted != undefined ? serviceFilter.estimatedDateFormatted.toLowerCase().match(filter) : ""
                    var closedCanceledDateFormatted = serviceFilter.closedCanceledDateFormatted != null && serviceFilter.closedCanceledDateFormatted != undefined ? serviceFilter.closedCanceledDateFormatted.toLowerCase().match(filter) : ""
                    var id = serviceFilter.id.toString().match(filter);

                    if(userNameRegister != null) { return userNameRegister; } 
                    else if(dateRegisterFormatted != null) { return dateRegisterFormatted; }
                    else if(customerName != null) { return customerName; } 
                    else if(customerOriginDescription != null) { return customerOriginDescription; } 
                    else if(customerPhone != null) { return customerPhone; } 
                    else if(estimatedDateFormatted != null) { return estimatedDateFormatted; }
                    else if(closedCanceledDateFormatted != null) { return closedCanceledDateFormatted; }
                    else if(userNameResponsible != null) { return userNameResponsible; }
                    else { return id; }
                });
            },
            
            showCheckOrWireNumber() {
                return this.typePaymentSelected != null && 
                       this.typePaymentSelected != undefined && 
                      (this.typePaymentSelected.id == 1 || 
                       this.typePaymentSelected.id == 3)
            },

            textCheckOrWireTransfer() {
                if (this.isWireTransfer) {
                    return 'Tracking Number'
                }
                else if (this.isCheck) {
                    return 'Check Number'
                }
                else {
                    return ''
                }
            },

            isWireTransfer() {
                return this.showCheckOrWireNumber && this.typePaymentSelected.id == 1
            },

            isCheck() {
                return this.showCheckOrWireNumber && this.typePaymentSelected.id == 3
            },

            isBillable() {
                return this.serviceTypeSelected != null && this.serviceTypeSelected != undefined && this.serviceTypeSelected.id == 1;
            }
        },

        watch: {

            paymentDate () {
                this.paymentDateFormatted = this.formatDate(this.paymentDate)
            },
        },

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__main)';
                        break;

                    case 1:
                        color = 'var(--color__silver)';
                        break;

                    case 2:
                        color = 'var(--color__blue)';
                        break;

                    case 3:
                        color = 'var(--color__red)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },
            
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async getRegisters() {

                this.listServiceCalls = await this.$store.dispatch("serviceCallsModule/List");
                this.listServiceCallsFiltered = [...this.listServiceCalls];
                this.listStatus = await this.$store.dispatch("serviceCallsModule/ListStatus");
                this.listServiceType = await this.$store.dispatch("serviceCallsModule/ListServiceType");
                this.listCustomerOrigin = await this.$store.dispatch("customerOriginModule/List");
                this.listSellers = await this.$store.dispatch("userModule/ListSalesPerson");
                this.listTypePayments = await this.$store.dispatch("serviceModule/ListTypePayments");
                
                this.filter.listStatusSelected = [];

                if (this.listStatus != null && this.listStatus != undefined && this.listStatus.length > 0) {
                    this.filter.listStatusSelected.push(this.listStatus.filter(sts => sts.id == 0)[0]);
                    this.filter.listStatusSelected.push(this.listStatus.filter(sts => sts.id == 2)[0]);
                }

                this.filterRegisters();
            },

            async filterRegisters() {

                let idCustomerOrigin = 0;
                let idSeller = 0;
                let listFiltered = [...this.listServiceCalls];

                if (this.filter.customerOriginSelected != null && this.filter.customerOriginSelected != undefined) {
                    idCustomerOrigin = this.filter.customerOriginSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.idCustomerOrigin == idCustomerOrigin);
                }

                if (this.filter.sellerSelected != null && this.filter.sellerSelected != undefined) {
                    idSeller = this.filter.sellerSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.idUserResponsible == idSeller);
                }

                if (this.filter.listStatusSelected != null && this.filter.listStatusSelected != undefined && this.filter.listStatusSelected.length > 0) {

                    let listIDStatus = [];

                    this.filter.listStatusSelected.forEach(itemStatusSelected => {
                        listIDStatus.push(itemStatusSelected.id);
                    });

                    listFiltered = listFiltered.filter(ser => listIDStatus.includes(ser.status));
                }

                this.listServiceCallsFiltered = listFiltered;
            },

            showNotes(type, id) {
                this.id = id;
                this.type = type;
                this.checkView = false;
                this.showDialogNotes = true;
                this.notes = '';                
                this.serviceTypeSelected = null;
                this.typePaymentSelected = null;
                this.checkNumber = '';
                this.value = "$ 0,00";
            },

            confirm() {
                if (this.type == '[Cancel]') {
                    this.confirmCancel2();
                }
                else if (this.type == this.typeClose) {
                    this.confirmClose();
                }
            },

            confirmCancel2() {

                this.statusId = 3;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will CANCEL this Service Call, confirm your decision?',
                    methodConfirm: this.updateStatus,
                    params: this.id
                };

            },

            confirmClose() {

                this.statusId = 1;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will CLOSE this Service Call, confirm your decision?',
                    methodConfirm: this.updateStatus,
                    params: this.id
                };

            },

            async showView(item) {
                this.itemServiceCall = item;
                this.listHistory = await this.$store.dispatch("serviceCallsModule/ListHistory", item.id);

                await this.listHistory.forEach(async itemHistory => {

                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE_CALLS.value, idKey: itemHistory.id });

                    itemHistory.serviceCallsNotesPhotos = [];

                    let listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SERVICE_CALLS_NOTES_PICTURES.value);  
                    await listPhotos.forEach(async itemPhoto => {

                        itemHistory.serviceCallsNotesPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })
                    })

                    itemHistory.qtdPictures = itemHistory.serviceCallsNotesPhotos.length;
                });

                this.checkView = true;
                this.showDialogNotes = true;
            },

            async viewPhotos(idHistory) {
                let itemHistory = this.listHistory.filter(his => his.id == idHistory)[0];

                if (itemHistory != null && itemHistory != undefined) {
                    this.serviceCallsNotesPhotos = itemHistory.serviceCallsNotesPhotos;
                }

                this.showDialogNotesPictures = true;
            },

            async updateStatus() {

                this.showLoading();

                let updateStatusRequest = {
                    id: this.id,
                    status: this.statusId,
                    description: this.notes,
                    serviceType: null,
                    paymentType: null,
                    paymentCheckNumber: null,
                    paymentDate: null,
                    paymentValue: null,
                };

                if (this.type == this.typeClose && this.isBillable) {
                    
                    let serviceType = null;
                    let paymentType = null;
                    let paymentDate = this.paymentDate;
                    let paymentValue = formaterDecimalBRServer(this.value);

                    if (this.serviceTypeSelected != null && this.serviceTypeSelected != undefined) {
                        serviceType = this.serviceTypeSelected.id;
                    }

                    if (this.typePaymentSelected != null && this.typePaymentSelected != undefined) {
                        paymentType = this.typePaymentSelected.id;
                    }

                    updateStatusRequest.serviceType = serviceType;
                    updateStatusRequest.paymentType = paymentType;
                    updateStatusRequest.paymentCheckNumber = this.checkNumber;
                    updateStatusRequest.paymentDate = paymentDate;
                    updateStatusRequest.paymentValue = paymentValue;
                }

                let result = await this.$store.dispatch("serviceCallsModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();
                this.showDialogNotes = false;

                this.hideLoading();
            },

            async viewPaymentsDetails() {
                this.showDialogPaymentsDetails = true;
                this.paymentDetails.paymentTypeDescription = this.itemServiceCall.paymentTypeDescription;
                this.paymentDetails.paymentCheckNumber = this.itemServiceCall.paymentCheckNumber;
                this.paymentDetails.paymentDateFormatted = this.itemServiceCall.paymentDateFormatted;
                this.paymentDetails.paymentValueFormatted = this.itemServiceCall.paymentValueFormatted;
            },

            async confirmDownload(id) {

                this.showLoading();

                this.$vanKirkApi.get(`service-calls/download/${id}`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        var url = window.URL.createObjectURL(blob);
                        window.open(url);

                        this.getRegisters();
                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Error to generate service call: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Error to generate service call: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }
</style>